import React, { useEffect } from 'react';

function useOnOuterClick(ref, callback, condition) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (condition) {
          callback();
        }
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, condition]);
}

export default useOnOuterClick;

import { FaSearch } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { css } from 'theme-ui';
import styled from '../../lib/transient-styled';
import pxToRem from '../../utils/pixelsToRem';

const HeaderWidth = '(100% + 520px)';

export const Wrapper = styled.div(
  css({
    display: 'flex',
    position: 'relative',

    transition: 'width 0.6s',

    '& > :nth-of-type(n + 2)': {
      ml: '3px',
    },
  }),
  ({ $isSearchActive, $isFullBar }) =>
    css({
      width: $isFullBar
        ? [
            $isSearchActive
              ? ('calc(100% - 32px)', 'calc(100% - 32px)')
              : ('100%', '100%'),
            `calc(${HeaderWidth} * 0.62)`,
            `calc(${HeaderWidth} * 0.64)`,
            `calc(${HeaderWidth} * 0.64)`,
          ]
        : '140px',
      alignItems: !$isFullBar && 'center',
      justifyContent: !$isFullBar && 'flex-end',
      borderTop: $isFullBar && '1px solid',
      borderBottom: $isFullBar && '1px solid',
      borderColor: 'searchBorder',
    }),
);

export const Bar = styled.input(
  css({
    fontWeight: '400',
    fontSize: pxToRem(18),
    outline: 'none',

    transition: 'width 0.6s, padding 0.6s',

    '&::placeholder': {
      fontWeight: '300',
      color: 'text',
    },
  }),
  ({ $isFullBar }) =>
    css({
      width: $isFullBar ? '100%' : '135px',
      px: $isFullBar ? ['12px', '28px'] : '15px',
      py: $isFullBar ? '11px' : '4px',
      border: !$isFullBar && '1px solid',
      borderLeft: '1px solid',
      borderRight: '1px solid',
      borderColor: 'searchBorder',
    }),
);

export const SearchIcon = styled(FaSearch)(
  css({
    position: 'absolute',
    right: '10px',
    width: '14px',
  }),
);

export const CloseIcon = styled(GrClose)(
  css({
    position: 'absolute',
    top: '14px',
    right: '-30px',
    width: '12px',
    cursor: 'pointer',
  }),
);

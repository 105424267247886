import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';

export const CategoryContainer = styled(Flex)(
  css({
    height: '100%',
    padding: [
      '32px 20px 72px 20px',
      '32px 40px 72px 40px',
      '70px 40px 100px 80px',
    ],
    flexDirection: ['column', 'column', 'row'],
    gap: ['36px', '36px', '55px', '60px'],
    background: 'greyBackground',
    position: 'relative',
  }),
);

export const CategoryNav = styled.div(
  css({
    width: '162px',
    ml: ['23px', '23px', 0],
  }),
);

import styled from '@emotion/styled';
import { BiCalendar } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { css, Flex } from 'theme-ui';

export const Wrapper = styled.div(
  css({
    width: ['100%', '100%', 'auto'],
  }),
);

export const DateRangeWrapper = styled.div(
  css({
    position: 'absolute',
    mt: '4px',
    zIndex: '10',
    boxShadow: '0px 3px 6px 2px  rgba(186,186,186,1)',
  }),
);

export const Label = styled.label(
  css({
    position: 'absolute',
    top: '7px',
    left: '16px',
    letterSpacing: '0.035em',
    color: 'classyGrey',
    pointerEvents: 'none',
  }),
);

export const Input = styled(Flex)(
  css({
    minWidth: ['none', 'none', '112px'],
    gap: '12px',
    justifyContent: 'space-between',
    padding: '24px 8px 8px 16px ',
    color: 'text',
    whiteSpace: 'nowrap',
    borderBottom: '2px solid',
    borderColor: 'secondaryGreen',
    cursor: 'pointer',
    outline: 'none',
  }),
);

export const CalendarIcon = styled(BiCalendar)(
  css({
    position: 'absolute',
    top: '7px',
    right: '7px',
    width: '12px',
    color: 'secondaryGreen',
  }),
);

export const CloseIcon = styled(GrClose)(
  css({
    position: 'relative',
    top: '1px',
    width: '11px',
    cursor: 'pointer',
  }),
);

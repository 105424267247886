import { css, Flex } from 'theme-ui';
import styled from '../../lib/transient-styled';
import { CategoryContainer } from '../../shared-styled/layout/CategoryContainer';
import { ClassicAnchor } from '../../shared-styled/typography/Anchors';
import pxToRem from '../../utils/pixelsToRem';

export const NavigationPageContainer = styled(CategoryContainer)(
  ({ $isNotFoundPage }) =>
    css({
      height: $isNotFoundPage && 'calc(100vh - 80px)',
      padding: [
        '54px 22px',
        '60px 25px',
        '75px 50px 50px',
        '75px 70px 50px',
        '75px 100px 50px',
      ],
      background: 'linear-gradient(90deg, #191e1d 13.83%, #123239 53.2%)',
      color: 'white',
    }),
);

export const NavigationColumns = styled.nav(
  css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    flexDirection: ['column', 'column', 'row'],
  }),
);

export const NavColumn = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '20px',
  }),
);

export const StaticPageBlock = styled.div(
  css({
    padding: ['28px 10px', '30px 20px', '26px 40px'],
    background: 'inputBackground',
    borderRadius: '3px',
    fontWeight: '600',
    fontSize: [pxToRem(14), pxToRem(14), pxToRem(16)],
    color: 'white',
    letterSpacing: '0.035em',
    textAlign: ['center', 'center', 'left'],
    cursor: 'pointer',

    '&:hover': {
      background: 'focus',
      color: 'text',
    },
  }),
);

export const NavGroupTitle = styled.h4(
  css({
    fontWeight: '800',
    fontSize: pxToRem(18),
    lineHeight: '136%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: 'focus',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
);

export const MobileAnchor = styled(ClassicAnchor)(
  css({
    display: 'block',
    fontSize: pxToRem(16),
    mb: '16px',

    '&:hover': {
      color: 'focus',
    },
  }),
);

export const NavSeparator = styled.p(
  css({
    width: '50px',
    borderBottom: '1px solid white',
  }),
);

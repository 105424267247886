import Link from 'next/link';
import React from 'react';
import {
  NoUnderlineAnchor,
  NoUnderlineAnchorBlock,
} from '../../shared-styled/typography/Anchors';
import { IS_NEXT_EXPORT } from '../../utils/export';
import ExportPseudoAnchor from './UniversalLink.styled';

const absoluteToRelative = (href) => {
  if (typeof window === 'undefined') {
    return href;
  }
  const currentPath = window.location.href.split('/out/');

  if (typeof currentPath[1] === 'undefined') {
    return href;
  }

  const replacement = currentPath[1]
    .split('/')
    .slice(1)
    .map(() => '../')
    .join('');

  const link = `${
    (replacement || './') + href.replace(/\/#(.*)/, '')
  }/index.html`;
  return link.replaceAll('//', '/');
};

const UniversalLink = ({
  href,
  isBlock,
  useExportPseudoAnchorChild,
  children,
  className,
}) => {
  const pushLocation = (e) => {
    e.preventDefault();
    window.location.href = absoluteToRelative(href);
  };

  const Anchor = isBlock ? NoUnderlineAnchorBlock : NoUnderlineAnchor;

  return IS_NEXT_EXPORT ? (
    <Anchor onClick={pushLocation} href={href || ''} className={className}>
      {useExportPseudoAnchorChild ? (
        <ExportPseudoAnchor>{children.props.children}</ExportPseudoAnchor>
      ) : (
        children
      )}
    </Anchor>
  ) : (
    <Link href={href || ''} className={className} prefetch={false} passHref>
      {children}
    </Link>
  );
};

export default UniversalLink;

import dayjs from 'dayjs';
import React from 'react';
import { toast } from 'react-toastify';
import { postRequest } from '../../../api/wagtail';
import { TRIAL_UPGRADE_URL } from '../../../consts/urls';
import { useGlobalContext } from '../../GlobalContextProvider/GlobalContextProvider';
import { FreeTrialButton } from '../GatedBlock.styled';

const UpgradeTrialButton = ({ recordName, pageTitle }) => {
  const {
    user,
    contentView,
    isSubscribeRequestIsSent,
    setSubscribeRequestIsSent,
  } = useGlobalContext();

  const submitUpgrade = async () => {
    try {
      const data = {
        full_name: `${user.familyName} ${user.givenName}`,
        email: user.email,
        phone: user.userMetadata.phoneNumber,
        request_date: dayjs().format('YYYY-MM-DD'),
        request_page_url: window.location.href,
        request_page_name: recordName || pageTitle || ' ',
      };
      await postRequest(TRIAL_UPGRADE_URL, data);

      window.dataLayer.push({
        userId: user?.userId || 'anonymous',
        userEmail: user?.email,
        subscriptionType: user?.userLevel,
        contentView,
        pageOfSubmission: recordName || pageTitle || ' ',
        event: 'subscribeButtonSuccess',
      });

      toast.success('Request Demo sent successfully');
      setSubscribeRequestIsSent(true);
    } catch (e) {
      toast.error('Something went wrong');
      console.error(e);
    }
  };

  return user ? (
    <FreeTrialButton
      onClick={submitUpgrade}
      $isDisabled={user.trialUpgradeRequested || isSubscribeRequestIsSent}
    >
      Request Demo
    </FreeTrialButton>
  ) : null;
};

export default UpgradeTrialButton;

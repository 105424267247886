import { css, Flex } from 'theme-ui';
import styled from '../../lib/transient-styled';
import pxToRem from '../../utils/pixelsToRem';

export const HeaderCategoryNav = styled(Flex)(
  ({ $isModifiedHeaderOnPrint, isShownLastArrowInLink }) =>
    css({
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '25px',
      '& a:nth-last-of-type(1)::after': {
        '@media print':
          $isModifiedHeaderOnPrint && !isShownLastArrowInLink
            ? {
                content: '""',
              }
            : {},
      },
    }),
);

export const NavItem = styled.a(
  ({ $isDisabled = false, noArrow = false, isShownLastArrowInLink }) =>
    css({
      position: 'relative',
      fontSize: pxToRem(18),
      letterSpacing: '0.01em',
      color: !$isDisabled && 'secondaryGreen',
      textDecoration: 'none',
      textTransform: 'capitalize',
      textWrap: 'wrap',
      '@media print': {
        textDecoration: 'underline',
        color: 'text',
      },

      '::after': {
        display: noArrow && 'none',
        position: 'absolute',
        pl: '7px',
        content: '">"',
        '@media print': {
          display: isShownLastArrowInLink && 'none',
        },
      },
      '&:hover': {
        textDecoration: !$isDisabled && 'underline',
      },
    }),
);

export const PageTitle = styled.p(
  css({
    width: 'max-content',
    fontSize: pxToRem(18),
    textWrap: 'wrap',

    '::first-letter': {
      textTransform: 'uppercase',
    },
  }),
);

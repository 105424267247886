import {
  ABOUT_US_URL,
  ARMED_FORCES_URL,
  CONTACT_US_URL,
  DIRECTORY_URL,
  FAQ_URL,
  FREE_TRIAL_URL,
  MILITARY_ORGANIZATIONS_URL,
  NEWS_LIST_URL,
  OUR_SIGHTS_URL,
  POLICY_URL,
  REPORTS_LIST_URL,
  TERMS_URL,
  WEAPONS_URL,
} from './urls';

export const navigation = [
  {
    title: 'Databases',
    items: [
      { title: 'Weapons', url: WEAPONS_URL },
      { title: 'Armed Forces', url: ARMED_FORCES_URL },
      {
        title: 'Militant Organizations Archive',
        url: MILITARY_ORGANIZATIONS_URL,
      },
      { title: 'News', url: NEWS_LIST_URL },
      { title: 'Reports', url: REPORTS_LIST_URL },
    ],
  },
  {
    title: 'Account',
    items: [
      // { title: 'View A Demo', url: VIEW_DEMO_URL },
      { title: 'Free Trial', url: FREE_TRIAL_URL },
      { title: 'Contact Us', url: CONTACT_US_URL },
    ],
  },
  {
    title: 'About',
    items: [
      { title: 'About Military Periscope', url: ABOUT_US_URL },
      { title: 'In Our Sights', url: OUR_SIGHTS_URL },
      { title: 'Directory', url: DIRECTORY_URL },
      { title: 'FAQ', url: FAQ_URL },
      { title: 'Privacy Policy', url: POLICY_URL },
      { title: 'Terms and Conditions', url: TERMS_URL },
    ],
  },
];

export const fullNavigation = [
  {
    title: 'Weapons',
    url: WEAPONS_URL,
    items: [
      { title: 'Aircraft', url: '/weapons/aircraft/' },
      { title: 'Artillery / Guns', url: '/weapons/artilleryguns/' },
      {
        title: 'Ground Combat Vehicles',
        url: '/weapons/ground-combat-vehicles/',
      },
      { title: 'Land Mines', url: '/weapons/land-mines/' },
      {
        title: 'Naval Mines / Torpedoes',
        url: '/weapons/naval-minestorpedoes',
      },
      {
        title: 'Missiles / Rockets / Bombs',
        url: '/weapons/missilesrocketsbombs',
      },
      {
        title: 'Nuclear / Biological / Chemical',
        url: '/weapons/nuclearbiologicalchemical',
      },
      { title: 'Sensors / Electronics', url: '/weapons/sensorselectronics' },
      { title: 'Ships', url: '/weapons/ships' },
    ],
  },
  {
    title: 'Armed forces',
    url: ARMED_FORCES_URL,
    items: [
      {
        title: 'Africa',
        url: '/armedforces/africa/',
      },
      { title: 'Asia', url: '/armedforces/asia/' },
      {
        title: 'Australia / New Zealand',
        url: '/armedforces/australianew-zealand/',
      },
      {
        title: 'Central America / Caribbean',
        url: '/armedforces/central-americacaribbean',
      },
      { title: 'Eurasian Republics', url: '/armedforces/eurasian-republics/' },

      {
        title: 'Middle East / North Africa',
        url: '/armedforces/middle-eastnorth-africa/',
      },
      {
        title: 'Non-Aligned Europe',
        url: '/armedforces/non-aligned-europe/',
      },
      {
        title: 'NATO',
        url: '/armedforces/north-atlantic-treaty-organization-nato',
      },
      {
        title: 'South America',
        url: '/armedforces/central-americacaribbean',
      },
      { title: 'Southeast Asia', url: '/armedforces/southeast-asia/' },
      {
        title: 'United States of America',
        url: '/armedforces/united-states-america/',
      },
      {
        title: 'International Organizations',
        url: '/armedforces/international-organizations',
      },
    ],
  },
  {
    title: 'Militant organizations Archive',
    url: MILITARY_ORGANIZATIONS_URL,
    items: [
      { title: 'Africa', url: '/militant-organizations/africa/' },
      {
        title: 'East / Southeast Asia',
        url: '/militant-organizations/east-southeast-asia/',
      },
      { title: 'Europe', url: '/militant-organizations/europe/' },
      {
        title: 'Middle East',
        url: '/militant-organizations/middle-east',
      },
      { title: 'North America', url: '/militant-organizations/north-america/' },
      {
        title: 'Russia / Central Asia',
        url: '/militant-organizations/russia-central-asia/',
      },
      { title: 'South America', url: '/militant-organizations/south-america/' },
      { title: 'South Asia', url: '/militant-organizations/south-asia/' },
    ],
  },
];

export const fullMobileNavigation = [
  ...fullNavigation,
  {
    title: 'News',
    url: NEWS_LIST_URL,
    items: [],
  },
  {
    title: 'Reports',
    url: REPORTS_LIST_URL,
    items: [],
  },
];

class EventBus {
  constructor() {
    this.listeners = {};
  }

  addEventListener(eventName, callback) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }

    const listener = { eventName, callback };
    this.listeners[eventName].push(listener);

    return () => {
      this.removeEventListener(eventName, callback);
    };
  }

  removeEventListener(eventName, callback) {
    const listeners = this.listeners[eventName];
    if (listeners) {
      const index = listeners.findIndex(
        (listener) => listener.callback === callback,
      );
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    }
  }

  emit(eventName, data) {
    const listeners = this.listeners[eventName];
    if (listeners) {
      listeners.forEach((listener) => {
        listener.callback(data);
      });
    }
  }
}

const eventBus = new EventBus();
export default eventBus;

import styled from '@emotion/styled';
import { GrClose } from 'react-icons/gr';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const Wrapper = styled.div(
  css({
    width: ['100%', '100%', 'auto'],
  }),
);

export const TagBody = styled(Flex)(
  css({
    position: 'relative',
    padding: '16px 15px',
    fontWeight: '400',
    fontSize: pxToRem(12),
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    color: 'text',
    borderBottom: '2px solid',
    borderColor: 'secondaryGreen',
    cursor: 'pointer',
    outline: 'none',
  }),
);

export const FilterRemoveIcon = styled(GrClose)(
  css({
    position: 'absolute',
    top: '16px',
    right: '7px',
    width: '10px',
    cursor: 'pointer',
  }),
);

export const Label = styled.label(
  css({
    position: 'absolute',
    top: '8px',
    left: '15px',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(12),
    letterSpacing: '0.04em',
    color: '#717171',
  }),
);

export const selectStyles = {
  container: () => ({}),
  control: (_, { selectProps: { $isErrored } }) => ({
    minWidth: '194px',
    display: 'flex',
    padding: '6px 15px 7px',
    fontWeight: '400',
    fontSize: pxToRem(12),
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    color: '#1A3A35',
    borderBottom: '2px solid',
    borderColor: '#378D88',
    cursor: 'pointer',
    outline: 'none',
  }),
  menu: (base) =>
    css({
      ...base,

      position: 'absolute',
      width: ['100%', '100%', 'calc(100% + 60px)'],
      margin: 0,
    }),
  option: () => ({
    cursor: 'pointer',
    lineHeight: '28px',
    padding: '5px 0 5px 20px',
    fontSize: pxToRem(14),

    '&:hover': {
      borderLeft: '5px solid #378D88',
      background: '#F9F9F9',
      paddingLeft: '15px',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: () => ({
    marginRight: '8px',
  }),
  placeholder: () => ({}),
  singleValue: () => ({}),
  input: () => ({
    position: 'absolute',
  }),
  valueContainer: () => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '13px',
    marginTop: '20px',
  }),
  menuList: (base) => ({
    ...base,
    textAlign: 'left',
    padding: 0,

    '::-webkit-scrollbar': {
      width: '9px',
      height: '0px',
    },
  }),
};

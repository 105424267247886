import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import { GreenButton } from '../../shared-styled/blocks/buttons';
import pxToRem from '../../utils/pixelsToRem';

export const GatedBlockContainer = styled.div(({ $isHigher }) =>
  css({
    margin: [
      `${$isHigher ? '-102px' : '-60px'} -20px 0 -20px`,
      `${$isHigher ? '-102px' : '-60px'} -40px 0 -40px`,
      `${$isHigher ? '-102px' : '-60px'} -40px 0 -96px`,
    ],
  }),
);

export const GradientBlock = styled.div(
  css({
    position: 'relative',
    height: '60px',
    width: '100%',
    background:
      'linear-gradient(360deg, #FFFFFF 12.5%, rgba(246, 246, 246, 0) 208.33%)',
  }),
);

export const ButtonsBlock = styled(Flex)(
  css({
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: ['13px', '20px', '40px'],
    padding: [
      '15px 20px 55px 20px',
      '20px 96px 60px 20px',
      '40px 96px 70px 96px',
    ],
    background: 'white',
  }),
);

export const TextBlock = styled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['52px 20px', '52px 20px', '52px 96px 46px 96px'],
    background: 'greyBackground',
    fontFamily: 'Roboto',
  }),
);

export const TryItBlockTop = styled(Flex)(
  css({
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [
      '15px 20px 55px 20px',
      '20px 96px 60px 20px',
      '38px 96px 70px 96px',
    ],
    gap: '24px',
    width: '100%',
    background: 'white',
  }),
);

export const ClassicTryItBlock = styled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['72px 20px 0', '52px 20px 0', '72px 96px 20px 96px'],
  }),
);

export const TryItBlockBottom = styled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['72px 20px 0', '52px 20px 0', '72px 96px 20px 96px'],
  }),
);

export const BoldParagraph = styled.p(
  css({
    width: ['372px', '452px', '600px', '810px'],
    fontWeight: '600',
    fontSize: pxToRem(22),
    lineHeight: pxToRem(28),
    letterSpacing: '0.035em',
  }),
);

export const CircleList = styled.ul(
  css({
    mt: '36px',
    width: ['326px', '406px', '500px', '710px'],
    listStyle: 'inside',
    listStylePosition: 'outside',
  }),
);

export const CircleListItem = styled.li(
  css({
    mb: '16px',
  }),
);

export const ButtonWrapper = styled(Flex)(
  css({
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    px: ['12px', '16px', 0],
  }),
);

export const ButtonLabel = styled.p(
  css({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    letterSpacing: '0.05em',
    color: 'classyGrey',
    textAlign: 'center',
  }),
);

export const LoginButton = styled.button(
  css({
    width: 'max-content',
    border: '1px solid',
    borderColor: 'darkGreen',
    padding: '11px 34px',
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: 'darkGreen',
    background: 'white',
    textAlign: 'center',
    cursor: 'pointer',
  }),
);

export const FreeTrialButton = styled(GreenButton)(
  css({
    minWidth: '130px',
    padding: '12px',
  }),
  ({ $isDisabled }) =>
    css({
      background: $isDisabled && 'gray500',
      color: $isDisabled && 'grayMidDark',
      pointerEvents: $isDisabled && 'none',
    }),
);
export const ClassicTryItText = styled.p(
  css({
    mt: '80px',
    mb: '26px',
    fontSize: pxToRem(22),
    letterSpacing: '0.05em',
  }),
);

export const TryItTextTop = styled.p(
  css({
    fontSize: pxToRem(22),
    letterSpacing: '0.05em',
  }),
);

export const TryItTextBottom = styled.p(
  css({
    mt: '80px',
    mb: '26px',
    fontSize: pxToRem(22),
    letterSpacing: '0.05em',
  }),
);

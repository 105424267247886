import styled from '@emotion/styled';
import { css } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const FilterField = styled.div(({ $isWrapped }) =>
  css({
    position: 'relative',
    right: '1px',
    top: $isWrapped && ['-8px', '-8px', '0'],
    fontWeight: '400',
    fontSize: pxToRem(12),
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderTop: $isWrapped ? ['1px solid', '1px solid', 'none'] : 'none',
    borderColor: ['searchBorder', 'searchBorder'],
  }),
);

export const NotFound = styled.p(
  css({
    fontWeight: '600',
    fontSize: pxToRem(24),
    textAlign: 'center',
  }),
);

import styled from '@emotion/styled';
import { css } from 'theme-ui';
import StaticImage from '../../components/StaticImage';

const LinkImage = styled(StaticImage)(
  css({
    cursor: 'pointer',
  }),
);

export default LinkImage;

import styled from '@emotion/styled';
import { GrClose } from 'react-icons/gr';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const TagBody = styled(Flex)(
  css({
    padding: '16px 15px',
    fontWeight: '400',
    fontSize: pxToRem(12),
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    color: 'text',
    borderBottom: '2px solid',
    borderColor: 'secondaryGreen',
    cursor: 'pointer',
    outline: 'none',
  }),
);

export const TagRemoveIcon = styled(GrClose)(
  css({
    position: 'relative',
    top: '1px',
    ml: '7px',
    width: '10px',
    cursor: 'pointer',
  }),
);

// export const SearchBadge = styled(Badge)(
//   css({
//     position: 'absolute',
//     top: '8px',
//     right: '16px',
//     px: '18px',
//     py: '2px',
//     display: 'flex',
//     alignItems: 'center',
//     gap: '10px',
//     fontSize: pxToRem(18),
//     fontWeight: '300',
//     textTransform: 'capitalize',
//     bg: 'greyBackground',
//     borderRadius: '50px',
//     color: 'text',
//     border: '1px solid',
//     borderColor: 'searchBorder',
//   }),
// );

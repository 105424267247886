import { useBreakpointIndex } from '@theme-ui/match-media';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { ACTIVATE_FULL_BAR } from '../../consts/events';
import { MOBILE_BREAKPOINT_INDEX } from '../../consts/style-consts';
import { HOME_PAGE_URL } from '../../consts/urls';
import eventBus from '../../lib/eventBus';
import { kebabToTitle } from '../../utils/caseconverters';
import nameFix from '../../utils/nameFix';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import SearchTag from '../SearchTag/SearchTag';
import SubcategoryFilter from '../SubcategoryFilter/SubcategoryFilter';
import { Bar, CloseIcon, SearchIcon, Wrapper } from './SearchBar.styled';

const SearchBar = ({ initialTag, isFullBar, subcategories, className }) => {
  const router = useRouter();
  const index = useBreakpointIndex();
  const searchBar = useRef();

  const [tag, setTag] = useState(initialTag);
  const {
    isSearchActive,
    setIsSearchActive,
    setSearchValue,
    searchValue,
    setSearchContentType,
    isSubcategoryFilterActive,
  } = useGlobalContext();

  useEffect(() => {
    setSearchContentType(initialTag);
  }, [initialTag]);

  const closeIconHander = (value) => {
    setIsSearchActive(value);
    setSearchValue('');
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (router.query.search2) {
        router.push({
          pathname: router.asPath.replaceAll(/\?(.*)/gm, ''), // replace previous queries
          query: { search2: searchValue },
        });
      }
      router.push({
        pathname: router.asPath.replaceAll(/\?(.*)/gm, ''), // replace previous queries
        query: { search: searchValue },
      });
    }
  };

  const activateFullBar = () => {
    if (!isSearchActive) {
      setIsSearchActive(true);
    }
  };

  useEffect(() => {
    const removeListener = eventBus.addEventListener(ACTIVATE_FULL_BAR, () => {
      setTimeout(() => searchBar.current?.focus(), 300);
    });
    return () => removeListener();
  }, []);

  const isSearchBarDisplayed =
    router.asPath !== HOME_PAGE_URL || isSearchActive;

  const rootName = kebabToTitle(router.asPath.split('/')[1] || '');
  const isSubcategory = router.asPath.split('/').length > 3;

  const isMainFiltersDisplayed =
    tag &&
    isFullBar &&
    (rootName === 'news' || rootName === 'reports' || rootName === 'ddnc');
  const isSubcategoryFiltersDisplayed =
    isSubcategoryFilterActive &&
    isFullBar &&
    isSubcategory &&
    (rootName === 'militant organizations' ||
      rootName === 'weapons' ||
      rootName === 'armedforces');

  return (
    isSearchBarDisplayed && (
      <>
        <Wrapper
          $isSearchActive={isSearchActive}
          $isFullBar={isFullBar}
          className={className}
        >
          {!isFullBar && <SearchIcon />}
          <Bar
            type='text'
            ref={searchBar}
            onChange={handleChange}
            onClick={activateFullBar}
            onKeyDown={handleKeyDown}
            value={searchValue}
            placeholder={
              tag && isFullBar ? `Search in ${nameFix(tag)}` : 'Search'
            }
            $isFullBar={isFullBar}
          />
          {tag && isFullBar && <SearchTag tag={nameFix(tag)} setTag={setTag} />}
          {index > MOBILE_BREAKPOINT_INDEX && (
            <>
              {isSubcategoryFiltersDisplayed && (
                <SubcategoryFilter subcategories={subcategories} />
              )}
              {isMainFiltersDisplayed && <DateRangePicker />}
            </>
          )}
          {isSearchActive && (
            <CloseIcon onClick={() => closeIconHander(false)} />
          )}
        </Wrapper>
        {index <= MOBILE_BREAKPOINT_INDEX && (
          <>
            {isSubcategoryFiltersDisplayed && (
              <SubcategoryFilter
                subcategories={subcategories}
                className={className}
              />
            )}
            {isMainFiltersDisplayed && (
              <DateRangePicker className={className} />
            )}
          </>
        )}
      </>
    )
  );
};

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;

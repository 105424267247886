import React from 'react';
import StyledPrintButton from './PrintButton.styled';

const PrintButton = ({ className, user, handlePrint, isFullBar }) =>
  user ? (
    <StyledPrintButton
      onClick={handlePrint}
      className={className}
      $isFullBar={isFullBar}
    >
      Print
    </StyledPrintButton>
  ) : null;

export default PrintButton;

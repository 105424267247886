import styled from '@emotion/styled';
import { css } from 'theme-ui';
import { GreenButton } from '../../shared-styled/blocks/buttons';

const StyledPrintButton = styled(GreenButton)(
  css({
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto 0 auto 12px',
    padding: '0 28px',
  }),
  () =>
    css({
      '&:hover': {
        background: 'focus',
        color: 'text',
      },
    }),
  ({ $isFullBar }) =>
    css({
      marginLeft: $isFullBar ? '36px' : '12px',
    }),
);

export default StyledPrintButton;

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { css } from 'theme-ui';
import styled from '../../lib/transient-styled';
import { IS_NEXT_EXPORT } from '../../utils/export';
import pxToRem from '../../utils/pixelsToRem';

export const StAccordion = styled(Accordion)(
  css({
    my: '30px',
    border: 'none',
  }),
);

export const StAccordionItem = styled(AccordionItem)(
  css({
    color: 'white',
  }),
);

export const StAccordionItemPanel = styled(AccordionItemPanel)(
  css({
    fontWeight: '600',
    lineHeight: pxToRem(36),
    letterSpacing: '0.035em',
  }),
);

export const StAccordionItemButton = styled(AccordionItemButton)(
  ({ $uppercase }) =>
    css({
      position: 'relative',
      p: '18px 36px 18px 12px',
      backgroundColor: 'inherit',

      fontWeight: '800',
      fontSize: pxToRem(18),
      lineHeight: pxToRem(24),
      letterSpacing: '0.1em',
      textTransform: $uppercase ? 'uppercase' : 'none',

      borderBottom: '1px solid',
      borderColor: 'separator',
      cursor: 'pointer',

      '&[aria-expanded=true]': {
        color: 'darkYellow',
      },

      '&::after': {
        position: 'absolute',
        right: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        content: `url(${
          IS_NEXT_EXPORT
            ? 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDE2LjY2NjNWOS45OTk2N00xMCA5Ljk5OTY3VjMuMzMzMDFNMTAgOS45OTk2N0gxNi42NjY3TTEwIDkuOTk5NjdIMy4zMzMzNyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg=='
            : '/img/akar-icons_plus.svg'
        })`,
      },
      '&[aria-expanded="true"]::after': {
        position: 'absolute',
        right: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        content: `url(${
          IS_NEXT_EXPORT
            ? 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDE2LjY2NjNWOS45OTk2N00xMCA5Ljk5OTY3VjMuMzMzMDFNMTAgOS45OTk2N0gxNi42NjY3TTEwIDkuOTk5NjdIMy4zMzMzNyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg=='
            : '/img/akar-icons_plus.svg'
        })`,
      },
    }),
);

export const PseudoAccordionItem = styled.div(
  css({
    position: 'relative',
    p: '18px 36px 18px 12px',
    backgroundColor: 'inherit',

    fontWeight: '800',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    letterSpacing: '0.1em',
    color: 'white',
    textTransform: 'uppercase',

    borderBottom: '1px solid',
    borderColor: 'separator',
    cursor: 'pointer',

    '&:hover': {
      color: 'darkYellow',
    },
  }),
);

import dayjs from 'dayjs';
import { defaultDateFormat } from '../consts/date-formats';

/**
 * Get date range string
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {boolean} isFilled
 * @returns {string} date range string
 */
function getRangeString(startDate, endDate, isFilled) {
  const todayDate = new Date();
  const yesterdayDate = new Date(Date.now() - 86400000);

  if (isFilled) {
    if (
      dayjs(startDate).isSame(todayDate, 'day') &&
      dayjs(endDate).isSame(todayDate, 'day')
    ) {
      return 'Today';
    }
    if (
      dayjs(startDate).isSame(yesterdayDate, 'day') &&
      dayjs(endDate).isSame(yesterdayDate, 'day')
    ) {
      return 'Yesterday';
    }
    if (dayjs(startDate).isSame(endDate, 'day')) {
      return dayjs(startDate).format(defaultDateFormat);
    }

    return `${dayjs(startDate).format(defaultDateFormat)} – ${dayjs(
      endDate,
    ).format(defaultDateFormat)}`;
  }

  return 'All';
}

export default getRangeString;

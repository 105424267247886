import NextImage from 'next/image';
import React from 'react';
import { IS_NEXT_EXPORT } from '../../utils/export';

const domain = process.env.NEXT_PUBLIC_HOST_ENV
  ? `https://${process.env.NEXT_PUBLIC_HOST_ENV}`
  : 'https://dev.mp.quintagroup.com';

const customLoader = ({ src }) => domain + src;

const StaticImage = (props) =>
  IS_NEXT_EXPORT ? (
    <NextImage {...props} loader={customLoader} />
  ) : (
    <NextImage {...props} />
  );

export default StaticImage;

import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';

export const ChildrenWrapper = styled.div(
  css({
    flex: '1 1 0',
  }),
);

export const BasePageContainer = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }),
);

export const ArchivedMessage = styled.p(
  css({
    textAlign: 'center',
    fontSize: '2rem',
    marginTop: '2.5rem',
  }),
);

import styled from '@emotion/styled';
import { css } from 'theme-ui';

const ExportPseudoAnchor = styled.p(
  css({
    pl: '36px',
    py: '12px',
    color: 'inherit',
    textDecoration: 'inherit',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'listElementHover',
      borderLeft: '5px solid',
      borderColor: 'secondaryGreen',
      pl: '31px',
    },
  }),
);

export default ExportPseudoAnchor;

import { useEffect } from 'react';

const usePrint = (user, isShownPrintBtn) => {
  const handleBeforePrint = () => {
    const { userId, email } = user || {};
    window.dataLayer.push({
      event: 'Printing attempt',
      userId: userId || email || 'anonymous',
    });
  };

  const handlePrint = async () => {
    try {
      window.print();
    } catch (error) {
      console.error('Error while attempting to print:', error);
    }
  };

  useEffect(() => {
    if (!isShownPrintBtn) return;
    window.addEventListener('beforeprint', handleBeforePrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
    };
  }, [user, isShownPrintBtn]);
  return { handlePrint };
};

export default usePrint;

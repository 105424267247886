import React from 'react';
import { FilterField } from '../../shared-styled/blocks/SearchAndFiltersBlocks';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import { TagBody, TagRemoveIcon } from './SearchTag.styled';

const SearchTag = ({ tag, setTag }) => {
  const { setSearchContentType } = useGlobalContext();

  const removeTag = () => {
    setSearchContentType(null);
    setTag(null);
  };

  return (
    <FilterField>
      <TagBody>
        <span>{tag}</span> <TagRemoveIcon onClick={removeTag} />
      </TagBody>
    </FilterField>
  );
};

export default SearchTag;
